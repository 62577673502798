import React, { type FC, useEffect, useState, type MouseEvent, useCallback } from 'react'
import { CP_SUBMIT_WITH_ADC_ID_CREATE } from '../../../components/graphql/mutations'
import MiniNotepad from '../../../components/notepad'
import { useMutation } from '@apollo/client'
import TextInput from '../../../components/field-input'
import { type PopupProps, type GraphData } from '../../../utility/types'
import { useGraphData } from '../../../useGraphData'
import CPInfo from '../../../components/cp-info'
import {
  formatInputToDollar,
  formatKey,
  formatDateToMMddyy,
  parseDollarToNumber
} from '../../../utility/formatting'
import ActionButton from '../../../components/action-button'
import GenericInputBox from '../../../components/generic-input-box'
import ConfirmationModal from '../../../components/confirm-popup'
import closeIcon from '../../../utility/img/cancel.png'
import FRDataViewer from './FRDataViewer'
import CPAllocationSummary from './CPAllocationSummary'

const Popup: FC<PopupProps> = ({ show, mode, onClose, data, frData, setMessage, setMessageColor, refetch }) => {
  const formattedDate = formatDateToMMddyy(new Date())
  const [frValue, setFrValue] = useState(`${formattedDate}-`)
  const [noteText, setNoteText] = useState('')
  const [selectedPrefix1, setSelectedPrefix1] = useState('')
  const [selectedPrefix2, setSelectedPrefix2] = useState('')
  const [leftCptyCode, setLeftCptyCode] = useState(0)
  const [rightCptyCode, setRightCptyCode] = useState(0)
  const [graphData, setGraphData] = useState<GraphData | null>(null)
  const [loadingGraphData, setLoadingGraphData] = useState(true)
  const [createFRForApproval, { error, loading }] = useMutation(CP_SUBMIT_WITH_ADC_ID_CREATE)
  const [unsavedChanges, setUnsavedChanges] = useState(false)
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false)
  const [selectedCPId, setSelectedCPId] = useState<number | null>(null)
  const [setCpExpand] = useState<boolean>(false)
  const [allocateAmounts, setAllocateAmounts] = useState<Record<number, string>>({})
  const [tempAllocateValue, setTempAllocateValue] = useState('')
  const [cpIdList, setCpIdList] = useState('')

  useEffect(() => {
    if (data.length > 0) {
      setSelectedCPId(data[0].cPIssuanceId)
    }
  }, [data])

  useEffect(() => {
    if (selectedCPId !== null) {
      const defaultAllocation = formatInputToDollar(parseDollarToNumber(
        allocateAmounts[selectedCPId] ?? data.find((cp) => cp.cPIssuanceId === selectedCPId)?.notionalQtyReminder ?? '0'
      ))
      setTempAllocateValue(defaultAllocation)
    }
  }, [selectedCPId, allocateAmounts, data])

  useEffect(() => {
    const updatedCpIdList = data
      .map((cp) => {
        const allocateAmount = parseDollarToNumber(allocateAmounts[cp.cPIssuanceId] || cp.notionalQtyReminder || '0')
        return `${cp.cPIssuanceId}:${allocateAmount}`
      })
      .join('|')
    setCpIdList(updatedCpIdList)
  }, [allocateAmounts, data])

  const [loadedGraphData] = useGraphData()

  useEffect(() => {
    if (loadedGraphData?.mail) {
      setGraphData(loadedGraphData)
      setLoadingGraphData(false)
    }
  }, [loadedGraphData])

  useEffect(() => {
    const allocateCashChanged = Object.keys(allocateAmounts).some((cpId) => {
      const cp = data.find((cp) => cp.cPIssuanceId.toString() === cpId)
      if (!cp) return false
      const defaultNotionalQty = formatInputToDollar(parseDollarToNumber(cp.notionalQty || '0'))
      return allocateAmounts[parseInt(cpId)] !== defaultNotionalQty
    })

    setUnsavedChanges(
      frValue !== `${formattedDate}-` ||
      noteText !== '' ||
      allocateCashChanged
    )
  }, [frValue, noteText, allocateAmounts, data])

  const extractCpPairDetails = (cpPair: string) => {
    const [label, code] = cpPair.split('*')
    return {
      label,
      code: parseInt(code, 10)
    }
  }

  const originalValues = {
    frValue: `${formattedDate}-`,
    noteText: '',
    allocateCashV: ''
  }

  const cpPair = data[0].cpPair

  const cpPairs = cpPair.map(cp => ({
    ...cp,
    cpLeft: extractCpPairDetails(cp.cpLeft),
    cpRight: cp.cpRight.map(r => extractCpPairDetails(r))
  }))

  const dropdown1Props = {
    options: cpPairs.map(cp => cp.cpLeft),
    selectedOption: selectedPrefix1,
    selectedCode: leftCptyCode,
    setSelectedOption: setSelectedPrefix1,
    setSelectedCode: setLeftCptyCode
  }

  const selectedCode = dropdown1Props.selectedCode
  const matchingPair = cpPairs.find(e => e.cpLeft.code === selectedCode) ?? { cpRight: [] }

  const dropdown2Props = {
    options: matchingPair.cpRight,
    selectedOption: selectedPrefix2,
    selectedCode: rightCptyCode,
    setSelectedOption: setSelectedPrefix2,
    setSelectedCode: setRightCptyCode
  }

  useEffect(() => {
    if (cpPairs.length > 0) {
      setSelectedPrefix1(cpPairs[0].cpLeft.label)
      setLeftCptyCode(cpPairs[0].cpLeft.code)
      if (cpPairs[0].cpRight.length > 0) {
        setSelectedPrefix2(cpPairs[0].cpRight[0].label)
        setRightCptyCode(cpPairs[0].cpRight[0].code)
      }
    }
  }, [cpPair])

  useEffect(() => {
    if (error) {
      setMessage('An error occurred while updating status')
      setMessageColor('red')
    }
  }, [error, setMessage, setMessageColor])

  const stopPropagation = useCallback((event: MouseEvent) => { event.stopPropagation() }, [])

  const handleAccept = useCallback(async () => {
    if (!graphData) {
      setMessage('User data is not available')
      setMessageColor('red')
      return
    }

    const { data: responseData } = await createFRForApproval({
      variables: {
        cp_id_list: cpIdList,
        created_by: graphData?.mail,
        fr_code: `FR-${frValue}`,
        note: noteText,
        left_cpty_code: leftCptyCode,
        right_cpty_code: rightCptyCode
      }
    })

    const { status, error: responseError } = responseData.submitAndCreateADCFundingRequest

    setMessage(status ? 'Successfully created a FR submission' : responseError)
    setMessageColor(status ? 'green' : 'red')
    refetch()
    onClose()
  }, [graphData, data, frValue, noteText])

  const handleOnClose = useCallback(async () => {
    if (unsavedChanges) {
      setIsConfirmationModalOpen(true)
    } else {
      onClose?.()
    }
  }, [unsavedChanges, onClose])

  const handleAllocateChange = (value: string) => {
    setTempAllocateValue(value)
  }

  const handleAllocateSubmit = () => {
    if (selectedCPId === null) return
    setAllocateAmounts((prev) => ({
      ...prev,
      [selectedCPId]: tempAllocateValue
    }))
  }

  const handleDiscardChanges = useCallback(async () => {
    setAllocateAmounts({})
    setFrValue(originalValues.frValue)
    setNoteText(originalValues.noteText)
    setUnsavedChanges(false)
    setIsConfirmationModalOpen(false)
    onClose?.()
  }, [data, originalValues, onClose])

  const handleCancelClose = useCallback(async () => {
    setIsConfirmationModalOpen(false)
  }, [])

  const handleNumericInputBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    handleAllocateSubmit()
    e.currentTarget.value = formatInputToDollar(parseDollarToNumber(e.currentTarget.value))
  }

  const handleNumericInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      handleAllocateSubmit()
      e.currentTarget.value = formatInputToDollar(parseDollarToNumber(e.currentTarget.value))
    }
  }

  if (!show) return null

  return (
    <div className="fixed z-10 inset-0 overflow-y-auto">
      {isConfirmationModalOpen && <div className="fixed inset-0 bg-black bg-opacity-50"></div>}
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div className="fixed inset-0 transition-opacity" aria-hidden="true">
              <div className="absolute inset-0 bg-gray-500 opacity-75" />
          </div>
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
          <div onClick={stopPropagation} className="inline-block align-bottom
                                                  bg-white rounded-lg text-left
                                                  overflow-hidden shadow-xl transform transition-all
                                                  sm:my-8 sm:align-middle sm:max-w-2xl sm:w-auto">
              <div className="bg-white px-4 pt-5">
                  <div className="sm:flex sm:justify-between sm:items-start">
                      <button
                        onClick={handleOnClose}
                        className="absolute top-3 right-3 transition duration-150 ease-in-out rounded-full p-2"
                      >
                        <img src={closeIcon} alt="Close" width={24} height={24} />
                      </button>
                      <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                        <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                          {mode === 'merge' ? 'Merge Cards' : 'Card Details'}
                        </h3>
                        <div className="mt-2">
                          {mode === 'merge'
                            ? <CPInfo
                                data={data}
                                selectedCPId={selectedCPId}
                                setSelectedCPId={setSelectedCPId}
                                setCpExpand={() => setCpExpand}
                              />
                            : (
                              <div className="text-sm">
                                {Object.entries(data[0]).slice(1, -3).map(([key, value], i) => (
                                  <div className="flex" key={i}>
                                    <strong className="font-semibold whitespace-nowrap overflow-hidden overflow-ellipsis">
                                    {formatKey(key)}:
                                    </strong>
                                    <div className="text-sm text-gray-500 ml-2 whitespace-nowrap overflow-hidden overflow-ellipsis">
                                      {typeof value === 'object' ? JSON.stringify(value) : value}
                                    </div>
                                  </div>
                                ))}
                              </div>
                              )
                            }
                          <div className='flex-grow w-full items-center mt-2 space-x-1'>
                            {selectedCPId && (
                              <div>
                                <span className='font-bold'>Allocate: $ </span>
                                <GenericInputBox
                                  name={`allocate-${selectedCPId}`}
                                  type="text"
                                  className="text-right rounded-md border-gray-300 shadow-sm focus:ring-gray-100 focus:border-gray-300"
                                  isNumeric
                                  value={tempAllocateValue}
                                  onChange={(value) => { handleAllocateChange(value) }}
                                  onKeyDown={handleNumericInputKeyDown}
                                  onBlur={handleNumericInputBlur}
                                  placeholder={`Default: ${
                                    data.find((cp) => cp.cPIssuanceId === selectedCPId)?.notionalQty ?? '0'
                                  }`}
                                />
                              </div>)
                            }
                          </div>
                          <div className="bg-white">
                            <FRDataViewer frData={frData || []} />
                          </div>
                          <CPAllocationSummary data={data} allocateAmounts={allocateAmounts} />
                          <TextInput
                            type="text"
                            prefix="FR-"
                            className="flex items-center my-2"
                            inputClassName="flex-grow focus:ring-gray-100 focus:border-gray-300"
                            placeholder={`${formattedDate}-`}
                            value={frValue}
                            setValue={setFrValue}
                            dropdown1={dropdown1Props}
                            dropdown2={dropdown2Props}
                          />
                          <div className="mt-3 text-center">
                            <MiniNotepad noteText={noteText} setNoteText={setNoteText} autoExpand/>
                          </div>
                        </div>
                      </div>
                  </div>
              </div>
              <ActionButton
                  primaryLabel={'Generate FR'}
                  secondaryLabel="Cancel"
                  primaryAction={handleAccept}
                  secondaryAction={async () => { setFrValue(originalValues.frValue); onClose?.() }}
                  primaryLoading={loading}
                  primaryDisabled={loadingGraphData}
              />
          </div>
      </div>
      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        onConfirm={handleDiscardChanges}
        onCancel={handleCancelClose}
        title="Unsaved Changes"
        question="You have unsaved changes. Do you want to discard them?"
        confirmText="Discard Changes"
        cancelText="Cancel"
      />
  </div>
  )
}

export default React.memo(Popup)
