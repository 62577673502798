import { type CPProps } from '../../../utility/types'

export interface CardBodyProps {
  data: CPProps
  onClick: () => void
}

export interface PopupPropsLiquid {
  show: boolean
  onClose: () => void
}

export interface CardBodyCompProps {
  onClick: () => void
}

export interface Group {
  firstGroup: string[]
  secondGroup: string[]
}

export interface Issuer {
  displayName: string
}

export type MappingTable = Record<string, Group | undefined>

export const tradeDateMenu = [
  'Today',
  'All'
]
export interface AdditionalFRData {
  cPIssuanceId: number
  fundingRequestCode: string
  cpNotionalQtyAllocated: string
}
export interface FRDataViewerProps {
  frData: AdditionalFRData[]
}
