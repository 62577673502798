import React, { type FC } from 'react'
import { type FRDataViewerProps } from '../utils/types'

const FRDataViewer: FC<FRDataViewerProps> = ({ frData }) => {
  if (!frData || frData.length === 0) {
    return <p className="mt-4 text-gray-500">No Funding Request Data Available</p>
  }

  return (
    <div className="mt-4">
      <h4 className="text-md font-semibold mb-2">Funding Request Details</h4>
      <table className="w-full border-collapse border border-gray-300 text-sm text-center">
        <thead>
          <tr className="bg-gray-100">
            <th className="border border-gray-300 p-1">Funding Request Code</th>
            <th className="border border-gray-300 p-1">Notional Allocated</th>
          </tr>
        </thead>
        <tbody>
          {frData.map((row, index) => (
            <tr key={index}>
              <td className="border border-gray-300 p-1 text-center">
                {row.fundingRequestCode || 'N/A'}
              </td>
              <td className="border border-gray-300 p-1 text-right">
                {row.cpNotionalQtyAllocated || 'N/A'}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default FRDataViewer
