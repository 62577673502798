import type { AssetDetailsData, SummaryAssetData } from '../../../utility/types'

export const transformAssetData = (data: AssetDetailsData[], testDetails: boolean) => {
  return data.flatMap((asset) => {
    const commonFields = {
      UniqueBatchId: asset.UniqueBatchId,
      BuyOrSell: asset.BuyOrSell,
      Currency: asset.Currency,
      Price: asset.Price,
      Isin: asset.Isin,
      Country: asset.Country,
      Name: asset.Name,
      Quantity: asset.Quantity
    }

    const testsFields = {
      'SecurityTypeCheck*': asset.SecurityTypeCheck,
      'NotionalExposure*': asset.NotionalExposure,
      '20DAvgVol8*': asset.AvgVol20D,
      'SinglePos>15%(cpty)*': asset.SinglePosAgainstCpty,
      'SinglePos>15%(firm)*': asset.SinglePosAgainstCapMarketFirm,
      'OutstandingShares*': asset.OutstandingShares,
      'MarketCap<$1Bn*': asset.MarketCapLessThanB,
      Overall: asset.Overall
    }

    const ratingFields = {
      Fitch: asset.Fitch,
      Moody: asset.Moody,
      SAndP: asset.SAndP
    }

    if (asset.AssetType === 'EQTY') {
      const equityFields = {
        ...(testDetails ? {} : { PrimaryExchange: asset.PrimaryExchange }),
        Ticker: asset.Ticker,
        SecurityType: asset.SecurityType,
        Notional: asset.Notional
      }

      return {
        ...commonFields,
        ...equityFields,
        ...(testDetails ? testsFields : { Overall: asset.Overall })
      }
    } else if (asset.AssetType === 'BOND') {
      const bondFields = {
        CountryRegion: asset.CountryRegion,
        MarketValue: asset.MarketValue,
        MaturityDate: asset.MaturityDate
      }

      return {
        ...commonFields,
        ...bondFields,
        ...(testDetails ? { ...ratingFields, ...testsFields } : { Overall: asset.Overall })
      }
    }

    return []
  })
}

export const formInputSessionData = (data: SummaryAssetData) => {
  return {
    Counterparty: data.Counterparty ?? '',
    TradeDate: data.TradeDate ?? '',
    EffectiveDate: data.EffectiveDate ?? '',
    TrsFinancingRate: data.TrsFinancingRate ?? '',
    TrsFinancingRateType: data.TrsFinancingRateType ?? '',
    ScheduledMaturityDate: data.ScheduledMaturityDate ?? '',
    FinalSettlementDate: data.FinalSettlementDate ?? '',
    LiquidityCptyTtl: data.LiquidityCptyTotal ?? '',
    ProjectedInitialMargin: data.ProjectedInitialMargin ?? '',
    MainAccountAllocation: data.MainAccountAllocation ?? '',
    Liquidity: data.Liquidity ?? ''
  }
}
