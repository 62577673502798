import React, { type FC } from 'react'
import { type CPProps } from '../../../utility/types'
import { formatInputToDollar, parseDollarToNumber } from '../../../utility/formatting'

const CPAllocationSummary: FC<{ data: CPProps[], allocateAmounts: Record<number, string> }> = ({ data, allocateAmounts }) => {
  return (
    <div>
      <h4 className="text-md font-semibold my-2">Purposed Allocations Summary</h4>
      <table className="w-full border-collapse border border-gray-300 text-sm text-center">
        <thead>
          <tr className="bg-gray-100">
            <th className="border border-gray-300 p-1">CP security</th>
            <th className="border border-gray-300 p-1">Amount</th>
          </tr>
        </thead>
        <tbody>
            {data.map((cp) => (
                <tr key={cp.cPIssuanceId}>
                <td className="border border-gray-300 p-1 text-center">
                    {cp.security || 'N/A'}
                </td>
                <td className="border border-gray-300 p-1 text-right">
                    ${formatInputToDollar(parseDollarToNumber(allocateAmounts[cp.cPIssuanceId] || cp.notionalQty || 'N/A'))}
                </td>
            </tr>
            ))}
        </tbody>
      </table>
    </div>
  )
}

export default CPAllocationSummary
