import React from 'react'
import AvatarProfile from '../../components/avatar'
import RequestCard from '../../pages/Liquid/requests'

const LiquidDashboard = () => {
  return (
        <div className='w-screen'>
            <AvatarProfile />
            <RequestCard />
        </div>
  )
}

export default LiquidDashboard
