import React, { type FC } from 'react'
import { type CPProps, type CPInfoProps } from '../../utility/types'
import { formatKey } from '../../utility/formatting'

const CPDetails: FC<{ cp?: CPProps }> = ({ cp = {} }) => (
  <div className="cp-details">
    {Object?.entries(cp).slice(2, -3).map(([key, value]) => (
      <div className="flex" key={key}>
        <strong className="font-semibold text-sm">{formatKey(key)}:</strong>
        <p className="text-sm text-gray-500 ml-2">{value ?? ''}</p>
      </div>
    ))}
  </div>
)

const CPInfo: FC<CPInfoProps> = ({ data, selectedCPId, setSelectedCPId, setCpExpand }) => {
  return (
    <div className="space-x-1 align-items-center">
      <div className="flex">
        <h4 className="p-1 font-bold">CP Information:</h4>
        <div className="flex space-x-4">
          {data?.map((cp, index) => (
            <div
              key={index}
              onClick={() => {
                setSelectedCPId?.(cp.cPIssuanceId)
                setCpExpand?.(true)
              }}
              className={
                selectedCPId === cp.cPIssuanceId
                  ? 'bg-blue-200 p-1 rounded cursor-pointer'
                  : 'p-1 cursor-pointer'
              }
            >
              {cp.cPIssuanceId}
            </div>
          ))}
        </div>
      </div>
      {selectedCPId && <CPDetails cp={data.find((cp) => cp.cPIssuanceId === selectedCPId)} />}
    </div>
  )
}

export default CPInfo
