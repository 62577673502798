import React, { useState, type ChangeEvent, useEffect } from 'react'
import Card from '../../../components/card'
import { GET_CP_BY_DATES, GET_ALL_ISSUERS } from '../../../components/graphql/queries'
import { type CPProps } from '../../../utility/types'
import Popup from '../cp-pop-up'
import Message from '../../../components/message'
import CardBody from '../cards-body'
import { type CardBodyCompProps, type PopupPropsLiquid, type Issuer } from '../utils/types'
import { tradeDateMenu } from '../utils/types'
import { formatIssuerName } from '../../../utility/formatting'
import { useQueryWithRetry } from '../../../hooks/useQueryWithRetry'

const RequestCard = () => {
  const { data, refetch, QueryWithRetryComponent, loading, error } = useQueryWithRetry(
    {
      query: GET_CP_BY_DATES
    })

  const Issuers = useQueryWithRetry({ query: GET_ALL_ISSUERS })

  const [allIssuers, setAllIssuers] = useState<Issuer[]>([])
  const [selectedIssuer, setSelectedIssuer] = useState<string>('All Issuers')
  const [selectedTradeDate, setSelectedTradeDate] = useState<string>()
  const [selectedCards, setSelectedCards] = useState<number[]>([])
  const [selectedCardsData, setSelectedCardsData] = useState<CPProps[]>([])
  const [showCheckBoxes, setShowCheckBoxes] = useState(false)
  const [showPopup, setShowPopup] = useState(false)
  const [buttonName, setButtonName] = useState('Select Cards')
  const [message, setMessage] = useState<string | null>(null)
  const [messageColor, setMessageColor] = useState<'red' | 'green' | 'yellow'>('green')

  useEffect(() => {
    if (data?.getCPIssuanceByDates?.cpData) {
      applyFilters()
    }
  }, [data, selectedIssuer, selectedTradeDate])

  useEffect(() => {
    if (Issuers.data) {
      setAllIssuers(Issuers.data.getAllIssuers)
    }
  })

  const applyFilters = () => {
    let filteredArray = data.getCPIssuanceByDates.cpData

    if (selectedIssuer !== 'All Issuers') {
      filteredArray = filteredArray.filter((cp: CPProps) => cp.issuerName.includes(formatIssuerName(selectedIssuer)))
    }

    setSelectedCardsData(filteredArray)
  }

  const handleSelectButtonClick = () => {
    setShowCheckBoxes(!showCheckBoxes)
    if (showCheckBoxes) {
      if (selectedCards.length > 0) {
        const filteredArray = data.getCPIssuanceByDates.cpData.filter((cp: CPProps) => selectedCards.includes(cp.cPIssuanceId))
        setSelectedCardsData(filteredArray)
        setShowPopup(true)
      }
      setButtonName('Select Cards')
    } else {
      setButtonName('Merge Cards')
    }
  }

  const handlePopupCancel = () => {
    setSelectedCards([])
    setSelectedCardsData([])
    setShowPopup(false)
    applyFilters()
  }

  const handleIssuerButtonClick = (event: ChangeEvent<HTMLSelectElement>) => {
    setSelectedIssuer(event.currentTarget.value)
  }

  const handleTradeDateButtonClick = (event: ChangeEvent<HTMLSelectElement>) => {
    setSelectedTradeDate(event.currentTarget.value)
  }

  if (error ?? loading) return <QueryWithRetryComponent />

  return (
        <>
            <div className="inline-flex items-center w-full p-4">
              <select id="issuers" onChange={handleIssuerButtonClick} className="text-white bg-gray-800 hover:bg-black focus:ring-2
                          focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2">
                {allIssuers.map((issuer) => (<option key={issuer.displayName}>{issuer.displayName}</option>))}
              </select>
              <select id="tradeDate" onChange={handleTradeDateButtonClick} className="text-white bg-gray-800 hover:bg-black focus:ring-2
                          focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2">
                {tradeDateMenu.map((tradeDate) => (<option key={tradeDate}>{tradeDate}</option>))}
              </select>
              {message && <Message message={message} color={messageColor} clearMessage={() => { setMessage('') }} />}
              <button
                onClick={handleSelectButtonClick}
                className="text-white bg-gray-800 hover:bg-black focus:ring-2
                          focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 ml-auto">
                {buttonName}
              </button>
            </div>
            <div className="flex flex-wrap justify-around mt-8">
                {data?.getCPIssuanceByDates?.cpData.length > 0
                  ? [...selectedCardsData].sort((a: CPProps, b: CPProps) => b.cPIssuanceId - a.cPIssuanceId).map((cp: CPProps, index: number) =>
                      cp && <Card
                            refetch={refetch}
                            key={index}
                            isCp
                            cpIssuanceId={cp.cPIssuanceId}
                            selectedCards={selectedCards}
                            setSelectedCards={setSelectedCards}
                            showCheckBoxes={showCheckBoxes}
                            CardBodyComponent={(cardBodyProps: CardBodyCompProps) =>
                                                <CardBody
                                                  data={cp}
                                                  {...cardBodyProps}
                                                />
                                              }
                            PopupComponent={(popupProps: PopupPropsLiquid) =>
                              <Popup
                                mode="cardDetail"
                                data={[cp]}
                                frData={data.getCPIssuanceByDates.frData}
                                setMessage={setMessage}
                                setMessageColor={setMessageColor}
                                refetch={refetch}
                                {...popupProps}
                              />
                            }
                        />
                    )
                  : <p>No data found</p>}
            </div>
            {showPopup &&
                <Popup
                    show={showPopup}
                    mode="merge"
                    data={selectedCardsData}
                    frData={data.getCPIssuanceByDates.frData}
                    onClose={handlePopupCancel}
                    setMessage={setMessage}
                    setMessageColor={setMessageColor}
                    refetch={refetch}
                />
            }
        </>
  )
}

export default RequestCard
